<template>
  <!-- 会员资产明细 -->
  <div>
    <!-- 搜索区域 -->
    <div class="form">
      <!-- 手机号查询 -->
      <div>
        <span>按手机号查询:</span>
        <el-input placeholder="请输入内容" v-model="form.phone" clearable style="width: 200px; display: block"> </el-input>
      </div>
      <div>
        <span>按姓名查询:</span>
        <el-input placeholder="请输入内容" v-model="form.name" clearable style="width: 200px; display: block"> </el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px">搜索</el-button>
      <el-button type="primary" icon="el-icon-refresh" style="height: 40px; margin-top: 40px" @click="restForm">重置</el-button>
    </div>
    <!-- 表单区域 -->
    <div style="margin-top: 30px">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="buyUserId" label="序号" width="" align="center"> </el-table-column>
        <el-table-column prop="buyTel" label="手机号" width="" align="center"> </el-table-column>
        <el-table-column prop="buyName" label="姓名" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenName" label="数字通证" width="" align="center"> </el-table-column>
        <el-table-column prop="number" label="CNY" width="" align="center"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页选项 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 50, 100]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="100"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phone: '',
        name: ''
      },
      tableData: [],
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4
    }
  },
  created() {
    this.onMenBer()
  },
  methods: {
    onMenBer() {
      this.axios
        .get('/admin/turnover/adminFindAll', {})
        .then(res => {
          console.log('res :>> ', res)
          this.tableData = res.data.data.page.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    on() {
      console.log(123)
    },
    // 重置
    restForm() {
      this.form = {
        phone: '',
        name: ''
      }
    },
    // 删除
    handleDelete() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      // console.log(index, value)
    },

    // 编辑
    handleEdit(index, value) {
      console.log(index, value)
    },

    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  div {
    margin-right: 20px;
    span {
      font-weight: bold;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
</style>
